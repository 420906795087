import React from 'react';
import Layout from '../../../components/Layout';
import NavOne from '../../../components/NavOne';
import PageHeader from '../../../components/PageHeader';
import Footer from '../../../components/Footer';
import DepartmentDetails from '../../../components/DepartmentDetails';

const FaqPage = () => {
  return (
    <Layout pageTitle='Department of Family Medicine | LUTH'>
      <NavOne />
      <PageHeader title='Department of Family Medicine' />
      <DepartmentDetails title='Department of Family Medicine' />
      <Footer />
    </Layout>
  );
};

export default FaqPage;
